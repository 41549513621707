import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const MembershipApiEndpoints = {
  GET_ACTIVE_MEMBERSHIP: {
    path: '/v1/users/self/active-membership',
  },
  GET_CANCELLATION_EMPLOYERS: {
    path: '/v1/membership-cancellations/employers',
  },
  GET_CANCELLATION_NOTES: {
    path: '/v1/membership-cancellations/:cancellationUuid/notes',
  },
  GET_CANCELLATION_WORKPLACES: {
    path: '/v1/membership-cancellations/workplaces',
  },
  GET_CANCELLATIONS: {
    path: '/v1/membership-cancellations',
  },
  GET_IS_MEMBER: {
    path: '/v1/is-member',
  },
  GET_SELF: {
    path: '/v1/users/self',
  },
  POST_CANCELLATION_FANE2_REFRESH: {
    path: '/v1/membership-cancellations/update-status',
  },
  POST_CANCELLATION_NOTE: {
    path: '/v1/membership-cancellations/:cancellationUuid/notes',
  },
  POST_CANCELLATION_TRANSITION: {
    path: '/v1/membership-cancellations/:cancellationUuid/transition',
  },
} satisfies FfNgxEndpointRecords;
